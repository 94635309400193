.change{
    /* font-fami
    ly: "Poetsen One", sans-serif; */
    font-family: "Open Sans", sans-serif;
    color: white;
}
.clr_privacy{
    font-family: "Open Sans", sans-serif;
    /* font-family: "Poetsen One", sans-serif; */
    color: white;
    text-align: justify;
}
.home-head {
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px !important;
    color: white !important;
  }