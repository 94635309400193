

h1, .home-head {
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 70px !important;
    color: white !important;
   
  }
 span{
    background-color: white;
    padding: 5px;
    font-size: 25px;
    font-weight: bold;
    border-radius: 5px;
    color: #2A1B94 !important;
  }
body {
	background-image: linear-gradient(to right, #2A1B94, #3E99D5);;
}

@media (max-width: 767px) {
  h1, .home-head {
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px !important;
    color: white !important;
  }
 span{
    background-color: white;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    color: #2A1B94 !important;
  }
  .comingSoon-image{
    width: 100% !important;
  }
}